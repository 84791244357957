#form-signup {
   transform: translate(-50%, -50%);
   top: 50%;
   left: 50%;

   .perception-btn {
      width: 100%;
   }

   input:not([type='checkbox']) {
      margin: 0;
      margin-top: 1em;
      outline: none;
  }

   #select-signup-form {
      button {
         background-color: transparent;         
         outline: none;
         box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
         box-shadow: inset 0 0 0 1px rgba(209, 213, 219, 0.5);
         border-radius: 0.375rem;
         margin-top: 1em;
         --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
         --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
         box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
         --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
         --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
         box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
         --tw-ring-inset: inset;
         --tw-ring-opacity: 1;
         --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
      }
      input {
         display: block;
         width: 100%;
         border-radius: 0.375rem;
         border: 0;
         background-color: transparent;
         color: #232222;
         padding: 0.5rem;
         margin: 0;
         text-transform: lowercase;
         font-size: 0.875rem;
         outline: none;
         --tw-text-opacity: 1;
         color: rgb(156 163 175 / var(--tw-text-opacity));
         line-height: 1.5rem;

         &::placeholder {
           color: rgba(156, 163, 175, 1);
         }
      }

      ul {
         background-color: white;
         box-shadow: inset 0 0 0 1px rgba(209, 213, 219, 0.5);
         margin-top: 0;


         li {
            color: black;
            cursor: pointer;
         }
      }
   }
}