@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/common/buttons/buttons.scss";
@import "styles/animation.scss";

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #2d2d2d;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 6px;
  border: 3px solid #2d2d2d;
}

/* Pour Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #555 #2d2d2d;
}