/* --------------- BASES CLASSES --------------- */
.perception-btn {
  @apply transition duration-200 ease-in-out font-semibold shadow-sm flex justify-center items-center;
  
    &.disabled {
      @apply bg-perception-gray-700 pointer-events-none border-gray-500 text-white;
        
      &:hover {
        @apply bg-gray-500;
      }
   }

   svg {
    width: 1rem;
    height: 1rem;
   }

   &.xs {
      @apply text-xs px-4 py-1;
   }

   &.small {
     @apply text-sm px-5 py-2;
   }

   &.medium {
     @apply px-4 py-2;
   }

   &.large {
     @apply px-6 py-4;
   }

   @screen md {
     &.medium {
       @apply px-4;
     }

     &.large {
       @apply py-4;
     }
   }

   @screen lg {
     &.medium {
       @apply px-6;
     }

     &.large {
       @apply px-10;
     }
   }
 }

/* --------------- VARIANTS CLASSES --------------- */
 .primary-btn {
   @apply bg-black text-white border border-black;

   &:hover {
     @apply bg-black bg-opacity-70;
   }

   &[disabled] {
     @apply bg-gray-500 border-gray-500 cursor-not-allowed;
   }
 }

 .secondary-btn {
   @apply bg-white text-black border border-black;

   &:hover {
     @apply bg-gray-200;
   }
 }

 .tertiary-btn {
   @apply bg-perception-blue text-white border border-perception-blue;

   &:hover {
     @apply bg-blue-800;
   }
 }

 .variant-btn {
   @apply bg-perception-gray-700 text-gray-300 border border-gray-500 transition duration-300 ease-in-out;

   &:hover {
     @apply bg-perception-gray-800 bg-opacity-50;
   }
 }

 .deletion-btn {
  @apply bg-perception-error-500 text-gray-300 border border-perception-error-800 transition duration-300 ease-in-out;

  &:hover {
    @apply bg-perception-error-800 bg-opacity-50;
  }
 }

 .trial-btn {
   @apply border border-perception-blue text-perception-blue;
   background: transparent;
 }